import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Solutionled({data}) {
    return (
        <section className="solutionled p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.solutions_body_txt}>
                            <h2>{checkData(data, 'solutions_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div>{HtmlParser(checkData(data, 'solutions_description'))}</div>
                        <div className="btn_talk">
                            <Link to={checkData(data, 'solution_btn_url')}
                                  className="border_btn">{checkData(data, 'solutions_btn_txt')}</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Solutionled;
